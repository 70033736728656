<template>
	<el-dialog
		:visible.sync="visible"
		:show-close="false"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		class="role-dialog"
		width="550px">
		<span slot="title" class="dialog-title">
			<span>{{title}}</span>
			<img src="@/assets/images/close.png" alt="" @click="visible = false">
		</span>
		<el-form :model="dataForm" :rules="dataRule" label-width="110px" ref="dataForm">
            <el-form-item label="地区" prop="rangeId">
				<el-select size="small" v-model="dataForm.rangeId" filterable :disabled="readonly">
					<el-option v-for="(item,i) in areaList" :key="i" :value="item.id" :label="item.name"></el-option>
				</el-select>
            </el-form-item>
			<el-form-item label="培训中心" prop="trainCenterId">
				<el-select size="small" v-model="dataForm.trainCenterId" filterable :disabled="readonly" @change="handleRoom(false)">
					<el-option v-for="(item,i) in trainCenterList" :label="item.name" :value="item.id" :key="i"></el-option>
				</el-select>
            </el-form-item>
			<el-form-item label="会场名称" prop="roomId">
				<el-select size="small" v-model="dataForm.roomId" filterable :disabled="readonly" @change="handleRoom(false)">
					<el-option v-for="(item,i) in roomList" :key="i" :value="item.id" :label="item.name"></el-option>
				</el-select>
            </el-form-item>
			<el-form-item label="培训地点" prop="trainId">
                <el-input size="small" v-model="trainName" placeholder="培训地点" :disabled="true"></el-input>
            </el-form-item>
			<el-form-item label="课程名称" prop="courseId">
				<el-select size="small" v-model="dataForm.courseId" filterable :disabled="readonly" @change="handleCourse">
					<el-option v-for="(item,i) in courseList" :key="i" :value="item.id" :label="item.name"></el-option>
				</el-select>
            </el-form-item>
			<el-form-item label="课程编号" prop="trainId">
                <el-input size="small" v-model="courseCode" placeholder="课程编号" :disabled="true"></el-input>
            </el-form-item>
			<el-form-item label="培训期间" prop="startTime">
				<el-date-picker
					v-model="trainTime"
					type="daterange"
					value-format="yyyy-MM-dd"
					range-separator="~"
					:disabled="readonly"
					@change="handleTrainTime"
					start-placeholder="开始日期"
					end-placeholder="结束日期">
				</el-date-picker>
            </el-form-item>
			<el-form-item label="教师" prop="userId">
				<el-select size="small" v-model="dataForm.userId" :disabled="readonly" filterable @change="handleTeacher()">
					<el-option v-for="(item,i) in teacherList" :key="i" :value="item.id" :label="item.name"></el-option>
				</el-select>
            </el-form-item>
			<el-form-item label="教育计划编号" prop="eduPlanCode">
                <el-input size="small" v-model="dataForm.eduPlanCode" placeholder="教育计划编号" :disabled="true"></el-input>
            </el-form-item>
			<el-form-item label="报名上限" prop="enterMax">
                <el-input size="small" v-model="dataForm.enterMax" placeholder="报名上限" maxlength="4" :disabled="readonly"></el-input>
            </el-form-item>
			<el-form-item label="报名下限" prop="enterMin">
                <el-input size="small" v-model="dataForm.enterMin" placeholder="报名下限" maxlength="4" :disabled="readonly"></el-input>
            </el-form-item>
			<el-form-item label="截止时间" prop="enterStopTime">
				<el-date-picker
					v-model="stopTime"
					type="date"
					value-format="yyyy-MM-dd"
					:disabled="readonly"
					@change="handleStopTime"
					placeholder="选择日期">
				</el-date-picker>
            </el-form-item>
			<el-form-item label="备注" prop="remark">
				<el-input size="small"
					type="textarea"
					:rows="3"
					maxlength="480"
					v-model="dataForm.remark"
					placeholder="备注"
					:disabled="readonly">
				</el-input>
			</el-form-item>
        </el-form>
		<span slot="footer" class="dialog-footer">
			<el-button class="submit-btn" @click="submit" v-if="title != '查看培训'">确定</el-button>
		</span>
	</el-dialog>
</template>
<script>
import { debounce } from '@/utils/debounce'
export default {
	data() {
		return {
			title: '新增培训',
			visible: false,
			readonly: false,
			areaList: [],
			roomList: [],
			courseList: [],
			teacherList: [],
			trainCenterList : [],
			trainTime: '',
			stopTime: '',
			trainName: '',
			courseCode: '',
			submitUrl: 'addTrainOffline',
			dataForm: {
				rangeId: '',
				trainCenterId: '',
				roomId: '',
				trainId: '',
				courseId: '',
				startTime: '',
				endTime: '',
				userId: '',
				eduPlanCode: '',
				enterMax: '',
				enterMin: '',
				enterStopTime: '',
				remark: ''
			},
			dataRule: {
				rangeId: [
					{required: true,message: '请选择地区', trigger: "change"}
				],
				roomId: [
					{required: true,message: '请选择会场', trigger: "change"}
				],
				trainCenterId: [
					{required: true,message: '请选择培训中心', trigger: "change"}
				],
				trainId: [
					{required: true,message: '培训地点不能为空', trigger: "blur"}
				],
				courseId: [
					{required: true,message: '请选择课程', trigger: "change"}
				],
				startTime: [
					{required: true,message: '请选择培训时间', trigger: "blur"}
				],
				userId: [
					{required: true,message: '请选择教师', trigger: "change"}
				],
				eduPlanCode: [
					{required: true,message: '教育计划不能为空', trigger: "blur"}
				],
				enterMax: [
					{required: true,message: '报名上限不能为空', trigger: "blur"},
					{pattern: /^[1-9]\d*$/, message: '请输入大于0的正整数'}
				],
				enterMin: [
					{required: true,message: '报名下限不能为空', trigger: "blur"},
					{pattern: /^[1-9]\d*$/, message: '请输入大于0的正整数'}
				],
				enterStopTime: [
					{required: true,message: '请选择截止时间', trigger: "blur"},
				],
			}
		}
	},
	methods: {
		init(type,data) {
			this.visible = true;
			this.getCourseList();
			this.getAreaList();
			this.getRoomList();
			this.getTeacherList();
			this.getTrainCenterList();
			this.$nextTick(() => {
				this.$refs.dataForm.resetFields();
				this.trainTime = '';
				this.stopTime = '';
				this.trainName = '';
				this.courseCode = '';
				if(type == 'add') {
					this.title = '新增培训';
					this.submitUrl = 'addTrainOffline';
					this.readonly = false;
					delete this.dataForm.id;
				}
				else if(type == 'edit') {
					this.title = '修改培训';
					this.submitUrl = 'updateTrainOffline';
					this.readonly = false;
					this.setForm(data);
				}
				else {
					this.title = '查看培训';
					this.submitUrl = '';
					this.readonly = true;
					this.setForm(data);
				}
			})

		},
		// 获取会场数据
		getRoomList() {
			let that = this;
			that.$request.get(
				"roomList",
				true,
				{
					pageNo: 1,
					pageSize: 999
				},
				function (r) {
					if (r.code == "0") {
						that.roomList = r.data.list;
						that.roomList.forEach(item => {
							if(item.id == that.dataForm.roomId){
								that.trainName = item.className;
								that.dataForm.trainId = item.classId;
							}
						})
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		// 获取区域数据
		getAreaList() {
			let that = this;
			that.$request.post(
				"sysDataDetailPageList",
				false,
				{
					pageNo: 1,
					pageSize: 999,
					code: 'areaType'
				},
				function (r) {
					if (r.code == "0") {
						that.areaList = r.data.list;
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		// 获取课程数据
		getCourseList() {
			let that = this;
			that.$request.post(
				"publishCourseList",
				true,
				{
					eduType: '2'
				},
				function (r) {
					if (r.code == "0") {
						that.courseList = r.data;
						that.courseList.forEach(item => {
							if(item.id == that.dataForm.courseId){
								that.courseCode = item.code;
							}
						})
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		// 获取教师列表数据
		getTeacherList() {
			let that = this;
			that.$request.get(
				"getCourseTeacherList",
				true,
				{
					searchKey: ''
				},
				function (r) {
					if (r.code == "0") {
						that.teacherList = r.data;
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		getTrainCenterList() {
			let that = this;
			that.$request.post(
				"sysDataDetailPageList",
				false,
				{
					pageNo: 1,
					pageSize: 100,
					code: 'trainCenter'
				},
				function (r) {
					if (r.code == "0") {
						that.trainCenterList = r.data.list;
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		// 会场下拉处理
		handleRoom(firstFlag) {
			if(this.dataForm.userId && this.trainTime.length > 0 && firstFlag == false && this.title != '查看培训') {
				this.getEduCode();
			}
			this.roomList.forEach(item => {
				if(item.id == this.dataForm.roomId){
					this.trainName = item.className;
					this.dataForm.trainId = item.classId;
				}
			})
		},
		// 课程下拉
		handleCourse() {
			this.courseList.forEach(item => {
				if(item.id == this.dataForm.courseId){
					this.courseCode = item.code;
				}
			})
		},
		// 教师下拉
		handleTeacher() {
			if(this.dataForm.roomId && this.trainTime.length > 0) {
				this.getEduCode();
			}
		},
		// 获取教育编码
		getEduCode() {
			let that = this;
			that.$request.post(
				"getEduCode",
				true,
				{
					id: that.dataForm.id || '',
					roomId: that.dataForm.roomId,
					userId: that.dataForm.userId,
					startTime: that.trainTime[0]
				},
				function (r) {
					if (r.code == "0") {
						that.dataForm.eduPlanCode = r.data.planCode;
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		handleTrainTime() {
			this.dataForm.startTime = this.trainTime[0];
			this.dataForm.endTime = this.trainTime[1];
			if(this.dataForm.roomId && this.dataForm.userId) {
				this.getEduCode();
			}
		},
		handleStopTime() {
			this.dataForm.enterStopTime = this.stopTime;
		},
		setForm(dt){
			let that = this;
			that.$request.post(
				"trainOfflineInfo",
				true,
				{
					id: dt.id
				},
				function (r) {
					if (r.code == "0") {
						that.dataForm.id = r.data.id;
						that.dataForm.rangeId = r.data.rangeId;
						that.dataForm.trainCenterId = r.data.trainCenterId;
						that.dataForm.roomId = r.data.roomId;
						that.dataForm.trainId = r.data.trainId;
						that.dataForm.courseId = r.data.courseId;
						that.dataForm.startTime = r.data.startTime;
						that.dataForm.endTime = r.data.endTime;
						that.trainTime = [r.data.startTime,r.data.endTime];
						that.dataForm.userId = r.data.userId;
						that.dataForm.eduPlanCode = r.data.eduPlanCode;
						that.dataForm.enterMax = r.data.enterMax;
						that.dataForm.enterMin = r.data.enterMin;
						that.dataForm.enterStopTime = r.data.enterStopTime;
						that.stopTime = r.data.enterStopTime;
						that.dataForm.remark = r.data.remark;
						that.handleRoom(true);
						that.handleCourse();
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		// 提交
		submit:debounce(function() {
			if(Number(this.dataForm.enterMax) < 1) {
				this.$message.error('报名上限不能小于1');
				return
			}
			if(Number(this.dataForm.enterMin) < 1) {
				this.$message.error('报名下限不能小于1');
				return
			}
			if(Number(this.dataForm.enterMin) > Number(this.dataForm.enterMax)) {
				this.$message.error('报名下限不能大于报名上限');
				return
			}
			if(this.dataForm.enterStopTime > this.dataForm.startTime ||this.dataForm.enterStopTime == this.dataForm.startTime) {
				this.$message.error('报名截止时间必须小于培训开始时间');
				return
			}
			if(this.visible == false) {
				return
			}
			this.$refs['dataForm'].validate(valid => {
				if (valid) {
					let that = this;
					that.$request.post(
						that.submitUrl,
						true,
						that.dataForm,
						function (r) {
							if (r.code == "0") {
								that.visible = false;
								that.$emit('getDataList');
								that.$message.success('保存成功');
							} else {
								that.$message.error(r.msg);
							}
						}
					);
				} else {
					return false;
				}
			});
		},1000)
	}
}
</script>
<style lang="scss" scoped>
.dialog-title{
	display: block;
	text-align: center;
	span{
		color: #222;
		font-weight: 600;
		font-size: 20px;
	}
	img{
		width: 28px;
		float: right;
		cursor: pointer;
	}
}
.el-form-item{
	margin-bottom: 18px;
	.el-select,
	/deep/ .el-range-editor.el-input__inner,
	/deep/ .el-date-editor.el-input, .el-date-editor.el-input__inner{
		width: 100%;
	}
	/deep/ .el-input__inner{
		height: 32px;
		line-height: 32px;
	}
}
.submit-btn{
	width: 100%;
	background-color: #117FFC;
	color: #fff;
}
.role-dialog /deep/ .el-dialog{
	border-radius: 10px !important;
}
.role-dialog /deep/ .el-dialog__body{
	padding: 0 20px !important;
}
.role-dialog /deep/ .el-form-item__label{
	// padding: 0 !important;
	// line-height: 24px;
}
.role-dialog /deep/ .el-dialog__footer{
	padding: 20px 20px 40px !important;
}
</style>
